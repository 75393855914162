<template>
  <div class="DemandSource">
    <ds-header title="需求来源管理"></ds-header>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="showModalA('add')">
        <a-icon type="plus" /> 新增需求来源
      </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table :columns="table.columns" :data-source="table.dataInfo" rowKey="demandSourceId"
        :pagination="table.pagination.total ? table.pagination : false" @change="pageChange" bordered
        :scroll="{ x: 1500 }">
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="showModalB('unable', record)"
            v-if="Number(record.enableStatus)"> 禁用</a-button>
          <a-button class="btnClass" type="link" @click="showModalB('enable', record)" v-else> 启用 </a-button>
          <a-button class="btnClass" type="link" @click="showModalA('edit', record)"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="showModalB('dele', record)"> 删除 </a-button>
        </span>
      </a-table>
    </div>

    <a-modal v-model="modalVisibleA" :title="modalTitleA" :maskClosable="false" @ok="submitA">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="需求来源名称">
          <a-input v-model.trim="currentDemand.demandSourceName" placeholder="请输入需求来源名称，不超过20个字" :maxLength="20" />
        </a-form-model-item>
      </a-form>
    </a-modal>

    <a-modal v-model="modalVisibleB" :title="modalTitleB" :maskClosable="false" @ok="submitB">
      {{modalTextB}}
    </a-modal>
  </div>
</template>

<script>
import { demandSourceTableData } from "../help";
import { getDemandSourceList, addSource, editSource, enableSource, deleteSource } from "@/api/demandPoolShunyi";
// 用于初始化弹框状态
const defaultDemand = {
  demandSourceName: "",
  demandSourceId: "",
};
export default {
  name: "DemandSource",
  data() {
    return {
      modalVisibleA: false,
      modalTitleA: "",
      modalVisibleB: false,
      modalTitleB: "",
      modalTextB: "",
      currentDemand: defaultDemand,
      table: demandSourceTableData,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      const params = {
        page: this.table.pagination.current,
        size: this.table.pagination.pageSize,
      };
      const { code, data } = await getDemandSourceList(params);
      if (code == "200") {
        this.table.pagination.total = data.total;
        this.table.dataInfo = data.list;
      }
    },

    // 唤起弹框
    showModalA(type, record) {
      this.modalVisibleA = true;
      if (type === "add") {
        // 先清空
        this.modalTitleA = "新增需求来源";
        this.currentDemand = { ...defaultDemand };
      }
      if (type === "edit") {
        // 返显
        this.modalTitleA = "编辑需求来源";
        this.currentDemand = { ...record };
      }
      // 单 if 语句，方便后期扩展
    },
    // 提交
    async submitA() {
      // 校验输入值
      if (!this.currentDemand.demandSourceName) {
        this.$message.error("提交内容不能为空");
        return;
      }

      try {
        if (this.modalTitleA === "新增需求来源") {
          const params = { demandSourceName: this.currentDemand.demandSourceName };
          await addSource(params);
        }
        if (this.modalTitleA === "编辑需求来源") {
          const params = {
            demandSourceName: this.currentDemand.demandSourceName,
            demandSourceId: this.currentDemand.demandSourceId,
          };
          await editSource(params);
        }
        // 单 if 语句，方便后期扩展
        this.$message.success("提交成功");
        this.getList();
        this.modalVisibleA = false;
      } catch (err) {
        console.error(err);
        this.modalVisibleA = false;
        this.$message.error("提交失败，请稍后再试");
      }
    },

    // 唤起弹框
    showModalB(type, record) {
      this.modalVisibleB = true;
      this.currentDemand = { ...record };
      // 单 if 语句，方便后期扩展
      if (type === "enable") {
        // 启用
        this.modalTitleB = "启用需求来源提醒";
        this.modalTextB = "确定要启用此需求来源吗？";
        return;
      }
      if (type === "unable") {
        // 禁用
        this.modalTitleB = "禁用需求来源提醒";
        this.modalTextB = "确定要禁用此需求来源吗？";
        return;
      }
      if (type === "dele") {
        // 禁用
        this.modalTitleB = "确认删除需求来源";
        this.modalTextB = "删除后此数据将无法恢复，确定要删除吗？";
        return;
      }
    },

    async submitB() {
      const { demandSourceId } = this.currentDemand;
      try {
        if (this.modalTitleB === "启用需求来源提醒") {
          const params = { demandSourceId, enableStatus: "1" };
          await enableSource(params);
        }
        if (this.modalTitleB === "禁用需求来源提醒") {
          const params = { demandSourceId, enableStatus: "0" };
          await enableSource(params);
        }
        if (this.modalTitleB === "确认删除需求来源") {
          await deleteSource(demandSourceId);
          this.$message.success("提交成功");
        }
        this.$message.success("提交成功");
        this.getList();
        this.modalVisibleB = false;
      } catch (err) {
        console.error(err);
        this.modalVisibleB = false;
        this.$message.error("提交失败，请稍后再试");
      }
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
